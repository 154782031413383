@use '../config/' as *;
.widget-layout-19 {
    .waf {
        &-head {
            @extend %mb-20;
        }
        &-body {
            @extend %pb-10;
        }
    }
    .table {
        border: 0.1rem solid var(--neutral-20);
        border-bottom: unset;
        @extend %x-auto;
        @extend %border-radius-r;
        &-row,
        &-data {
            justify-content: flex-start;
        }
        &-row {
            border-bottom: unset;
        }
        &-data {
            border-bottom: 0.1rem solid var(--neutral-20);
            @extend %p-2;
        }
        &-head {
            .table-data {
                @extend %bg-neutral-5;
            }
        }
        &-item {
            @extend %p-6-4;
            @extend %border-radius-r;
            @extend %bg-neutral-0;
        }
        &-number {
            @extend %title-20;
        }
        &-title {
            @extend %title-60;
            @extend %flex-fs-c;
            @extend %gap-4;
            @extend %my-8;
        }
        &-footer {
            @extend %mt-4;
            @extend %flex-fs-c;
        }
        .text {
            @extend %text-left;
        }
    }
    .rank {
        flex-basis: 7.5rem;
        justify-content: center;
    }
    .federation {
        flex-basis: 21.5rem;
    }
    .country {
        flex-basis: 12rem;
    }
    .region {
        flex-basis: 20rem;
    }
    .gold,
    .silver,
    .bronze,
    .total {
        justify-content: center;
        flex-basis: 8rem;
    }
    .team-info {
        @extend %flex-c-c;
        @extend %gap-2;
    }
    .logo {
        width: 2.4rem;
        aspect-ratio: 1/1;
        @extend %circle-radius;
    }
    .swiper-button {
        @extend %d-none;
    }
    @each $medal , $medal-value in $medals {
        .#{$medal}-text {
            height: 3.2rem;
            aspect-ratio: 1/1;
            @extend %flex-c-c;
            @extend %p-2;
            @extend %body-lb;
            @extend %circle-radius;
            @extend %bg-#{$medal-value}-0;
        }
    }
    .btn {
        &-back,
        &-link {
            @extend %primary-50;
            @extend %gap-2;
        }
        &-back {
            &::before {
                @include icon(arrow-left, 19);
            }
        }
        &-link {
            &:after {
                @include icon(arrow-right, 19);
            }
        }
        &-text {
            @extend %text-mb;
        }
    }
}
.waf-medal-tally {
    .waf-head {
        margin-bottom: var(--space-10);
    }
    .table {
        border: unset;
        @extend %x-hidden;
        &-section {
            @extend %mr-4-neg;
        }
        &-list {
            @include card-count(1.1, var(--space-4));
        }
        &-item {
            border: 0.1rem solid var(--neutral-20);
            @extend %p-6;
        }
        &-head {
            .table-data {
                @extend %bg-transparent;
                &:first-child {
                    .text {
                        @extend %font-0;
                    }
                }
            }
        }
        &-data {
            border-bottom: unset;
        }
        &-row {
            &:not(:last-child) {
                border-bottom: 0.1rem solid var(--neutral-20);
            }
        }
        &-title {
            @include truncate-text(2);
            @extend %body-lb;
            @extend %m-0;
        }
        &-body {
            min-height: 15rem;
        }
    }
    .gold,
    .silver,
    .bronze {
        flex: 1;
    }
    .country {
        flex-basis: 21%;
        flex-grow: 1;
    }
    .btn-link {
        @extend %gap-2;
        @extend %flex-n-c;
    }
}
@include mq(col-tablet) {
    .widget-layout-19 {
        .waf-body {
            padding-bottom: 8rem;
        }
        .table {
            &-item {
                padding: 4rem;
            }
            &-data {
                flex-shrink: 1;
            }
            &-title {
                font-size: 2.4rem;
            }
        }
        .title {
            font-size: 4rem;
        }
        .federation {
            flex-basis: 33%;
        }
        .region {
            flex-basis: 21.5%;
        }
        .country {
            flex-basis: 21%;
        }
    }
    .waf-medal-tally {
        .waf-head {
            margin-bottom: var(--space-12);
        }
        .table {
            &-section {
                margin-right: 0;
            }
            &-list {
                overflow: unset;
                gap: unset;
            }
            &-item {
                padding: 2.4rem;
            }
            &-title {
                font-size: 1.6rem;
            }
        }
        .swiper {
            position: static;
        }
        .swiper-button {
            &-prev,
            &-next {
                display: flex;
                position: absolute;
                left: auto;
                top: 2.4em;
                @include border(1, primary-50);
                &::after {
                    color: var(--primary-50);
                    font-size: 2.4rem;
                }
                &.swiper-button-disabled {
                    opacity: 1;
                    @include border(1, neutral-40);
                    &::after {
                        color: var(--neutral-40);
                    }
                }
            }
            &-prev {
                right: calc(var(--container-white-space) + 6rem);
            }
            &-next {
                right: var(--container-white-space);
            }
        }
    }
}
@include mq(col-xl) {
    .waf-medal-tally {
        .swiper-button {
            &-prev {
                right: calc((var(--window-inner-width) - var(--container-max-width))/2 + 6rem);
            }
            &-next {
                right: calc((var(--window-inner-width) - var(--container-max-width))/2);
            }
        }
    }
}